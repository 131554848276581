import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Slide from './slide';
import Feed from './feed';
import Aside from './aside';
import Chip from 'Chip';

class Main extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { leftData, rightData } = this.props.content;
        const { slide, feed, viewMore } = leftData;
        const feedData = {
            feed,
            viewMore
        };
        
        return (
            <div className={styles.main}>
                <div className={styles.mainLeft}>
                    <Chip id="285926" type="recommend" title="轮播图" groupName="main左侧数据" content={slide} >
                        <Slide />
                    </Chip>
                    <Feed content={feedData} />
                </div>
                <div className={styles.mainRight}>
                    <Aside content={rightData} />
                </div>
            </div>
        );
    }
}

export default Main;
