import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import Slides from '@ifeng-fe/ui_pc_slides';
import styles from './index.css';

class Head extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        
        return (
            <div className={styles.head}>
                <span className={styles.headText}>{content.title}</span>
                <a href={content.url} target="_blank" className={styles.headMore}>
                    {content.moreText}
                </a>
            </div>
        );
    }
}

export default Head;
