import React from 'react';
import NoSSR from 'react-no-ssr';
import Chip from 'Chip';
import PropTypes from 'prop-types';
import styles from './index.css';
import UserInfo from '@ifeng-fe/ui_pc_userInfo';
import Search from '@ifeng-fe/ui_pc_search';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Nav from './nav';
import Logo from './logo';

class Header extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染网页布局
     */
    render() {
        const { content } = this.props;
        const { nav, search, logo } = content;

        return (
            <>
                <div className={styles.header}>
                    <Chip id="20002" type="struct" title="导航" groupName="头部" content={nav}>
                        <Nav />
                    </Chip>
                    <div className={styles.user}>
                        <NoSSR>
                            <UserInfo />
                        </NoSSR>
                    </div>
                </div>
                <div className={styles.search_bar}>
                    <Chip id="260272" type="struct" title="Logo" groupName="顶部" content={logo} >
                        <Logo />
                    </Chip>
                    <Chip id="20005" type="struct" title="搜索" groupName="头部" content={search}>
                        <Search />
                    </Chip>
                </div>
            </>
        );
    }
}

export default errorBoundary(Header);
