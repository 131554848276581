import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Chip from 'Chip';
import Footer from './footer';

class BottomFooter extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={styles.bottomFooter}>
                <Chip id="20012" type="struct" title="底部导航" groupName="底部" content={content}>
                    <Footer />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(BottomFooter);
