import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { NewsStream } from '@ifeng-fe/news-stream';
import Chip from 'Chip';
import '@ifeng-fe/news-stream/lib/NewsStream/style/news-stream-global.css';
import styles from './index.css';

class Slide extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { feed, viewMore } = this.props.content;

        return (
            <div className={styles.slide}>
                <Chip id="287891" type="recommend" title="信息流推荐位" groupName="main左侧数据" content={feed}>
                    <NewsStream isEnd={true} isDisplayLoadingMore={false} />
                </Chip>
                <Chip id="260275" type="struct" title="查看更多" groupName="main左侧数据">
                    <div className={styles.viewMore}>
                        <a href={viewMore.url} target="_blank" className={styles.viewMoreLink}>
                            点击查看更多
                        </a>
                    </div>
                </Chip>
            </div>
        );
    }
}

export default Slide;
