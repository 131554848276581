import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Head from './head';
import Chip from 'Chip';
import FeedList from './feedList';
import ImageList from './imageList';

class Aside extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            asideHead0,
            asideFeed0,
            asideHead1,
            asideFeed1,
            asideHead2,
            asideFeed2,
            // asideHead3,
            // asideFeed3,
            asideImageList,
        } = this.props.content;
        
        return (
            <>
                <Chip id="260880" type="struct" title="大湾区" groupName="main右侧数据" content={asideHead0} >
                    <Head />
                </Chip>
                <FeedList content={asideFeed0} />

                <Chip id="260276" type="struct" title="政能量" groupName="main右侧数据" content={asideHead1} >
                    <Head />
                </Chip>
                <FeedList content={asideFeed1} />

                <Chip id="260277" type="struct" title="暖新闻" groupName="main右侧数据" content={asideHead2} >
                    <Head />
                </Chip>
                <FeedList content={asideFeed2} />

                {/* <Chip id="260278" type="struct" title="政务推荐" groupName="main右侧数据" content={asideHead3} >
                    <Head />
                </Chip>
                <FeedList content={asideFeed3} /> */}

                <Chip id="260282" type="struct" title="图片列表" groupName="main右侧数据" content={asideImageList} >
                    <ImageList />
                </Chip>
            </>
        );
    }
}

export default Aside;
