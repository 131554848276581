import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Column1 from './column1';
import Column2 from './column2';
import Column3 from './column3';

class Column extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            columnHead1,
            columnFeed11,
            columnFeed12,
            columnFeed13,
            columnHead2,
            columnFeed2,
            columnHead3,
            columnFeed3
        } = this.props.content;
        const column1Data = {
            columnHead1,
            columnFeed11,
            columnFeed12,
            columnFeed13,
        };
        const column2Data = {
            columnHead2,
            columnFeed2,
        };
        const column3Data = {
            columnHead3,
            columnFeed3
        };

        return (
            <div className={styles.column}>
                <Column1 content={column1Data} />
                <Column2 content={column2Data} />
                <Column3 content={column3Data} />
            </div>
        );
    }
}

export default Column;
