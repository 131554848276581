import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * 定义 Logo 组件
 */
class Logo extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const {
            content
        } = this.props;

        return (
            <div className={styles.logo}>
                <a href={content?.url} target="_blank">
                    <img src={content?.logo} alt={content?.title} />
                </a>
            </div>
        );
    }
}

export default errorBoundary(Logo);
