import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import Head from '../head';
import Feed from '../feed';
import styles from './index.css';

class Column1 extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        tabNum: 0
    }

    clickTabHandle = (value) => {
        this.setState({
            tabNum: value
        });
    }

    render() {
        const {
            columnHead1,
            columnFeed11,
            columnFeed12,
            columnFeed13,
        } = this.props.content;
        const { tabNum } = this.state;

        return (
            <div className={styles.column1}>
                <Chip id="260279" type="struct" title="通栏tab1" groupName="通栏" content={columnHead1} >
                    <Head
                        clickTabHandle={this.clickTabHandle}
                        liveTabNum={tabNum}
                    />
                </Chip>
                {tabNum === 0 &&
                    <Chip id="285929" type="recommend" title="通栏tab信息流1" groupName="通栏" content={columnFeed11} >
                        <Feed />
                    </Chip>
                }
                {tabNum === 1 &&
                    <Chip id="285930" type="recommend" title="通栏tab信息流2" groupName="通栏" content={columnFeed12} >
                        <Feed />
                    </Chip>
                }
                {tabNum === 2 &&
                    <Chip id="285931" type="recommend" title="通栏tab信息流3" groupName="通栏" content={columnFeed13} >
                        <Feed />
                    </Chip>
                }
            </div>
        );
    }
}

export default Column1;
