import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ChipEdit from 'ChipEdit';
import chipDataTransform from 'chipDataTransform';
import Header from './header';
import Nav from './nav';
import Main from './main';
import Column from './column';
import BottomFooter from './bottomFooter';

const Layout = ({ content }) => {
    const {
        subNav,
        nav,
        search,
        logo,
        slide,
        feed,
        viewMore,
        asideHead0,
        asideFeed0,
        asideHead1,
        asideFeed1,
        asideHead2,
        asideFeed2,
        // asideHead3,
        // asideFeed3,
        columnHead1,
        columnFeed11,
        columnFeed12,
        columnFeed13,
        columnHead2,
        columnFeed2,
        columnHead3,
        columnFeed3,
        asideImageList,
        footer
    } = content;

    const headerData = {
        nav: subNav,
        search,
        logo
    };
    const mainData = {
        leftData: {
            slide,
            feed,
            viewMore
        },
        rightData: {
            asideHead0,
            asideFeed0,
            asideHead1,
            asideFeed1,
            asideHead2,
            asideFeed2,
            // asideHead3,
            // asideFeed3,
            asideImageList
        }
    };
    const columnData = {
        columnHead1,
        columnFeed11,
        columnFeed12,
        columnFeed13,
        columnHead2,
        columnFeed2,
        columnHead3,
        columnFeed3
    };

    return (
        <div className={styles.layout}>
           <Header content={headerData} />
           <Nav content={nav} />
           <Main content={mainData} />
           <Column content={columnData} />
           <BottomFooter content={footer} />
           <ChipEdit transform={chipDataTransform} />
        </div>
    );
};

Layout.propTypes = {
    content: PropTypes.object,
};

export default errorBoundary(Layout);
