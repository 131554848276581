import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import styles from './index.css';

class Feed extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={styles.feedBox}>
                {content.map((item, index) => {
                    const imageUrl = item?.thumbnails?.image?.[0]?.url || '';

                    return (
                        <>
                            <a
                                href={item.url}
                                target="_blank"
                                key={index}
                                className={styles.feed}
                            >
                                {imageUrl && <img
                                    className={styles.feedImage}
                                    src={formatImageWebpAuto(imageUrl, 320, 182)}
                                    alt={item.title}
                                />}
                                <div className={styles.feedText}>
                                    {item.title}
                                </div>
                            </a>
                            {(index + 1) % 3 === 0 && <div className={styles.clearfix} />}
                        </>
                    );
                })}
            </div>
        );
    }
}

export default Feed;
