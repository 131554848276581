import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';

class Nav extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    // 渲染导航
    navView(list = []) {
        return list.map((item, index) => (
            <li key={index}>
                <a href={item.url} target="_blank">
                    {item.title}
                </a>
            </li>
        ));
    }

    navRender = () => {
        const { content } = this.props;

        return (
            <div
                className={`${styles.nav_box} nav_box`}
                id="navigation"
            >
                <ul className={`${styles.nav} clearfix nav`}>
                    {this.navView(content)}
                </ul>
            </div>
        );
    };

    render() {
        return (
            <Chip id="260273" type="struct" title="大导航" groupName="顶部">
                {this.navRender()}
            </Chip>
        );
    }
}

export default Nav;
