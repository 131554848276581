import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import Head from '../head';
import Feed from '../feed';
import styles from './index.css';

class Column1 extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            columnHead3,
            columnFeed3
        } = this.props.content;

        return (
            <div className={styles.column2}>
                <Chip id="260281" type="struct" title="通栏3" groupName="通栏" content={columnHead3} >
                    <Head />
                </Chip>
                <Chip id="285933" type="recommend" title="通栏信息流3" groupName="通栏" content={columnFeed3} >
                    <Feed />
                </Chip>
            </div>
        );
    }
}

export default Column1;
