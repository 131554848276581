import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';

class Head extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
        clickTabHandle: PropTypes.func,
        liveTabNum: PropTypes.number,
    };

    render() {
        const {
            content,
            clickTabHandle,
            liveTabNum = 0
        } = this.props;

        return (
            <div className={styles.head}>
                {
                    content.length > 1 ? (
                        content.map((item, index) => {
                            return (
                                <span
                                    key={index}
                                    className={`${styles.moreTitle} ${liveTabNum === index ? styles.active : ''}`}
                                    onClick={() => { clickTabHandle(index); }}
                                >
                                    {item.title}
                                </span>
                            );
                        })
                    ) : (
                        <span className={styles.singleTitle}>
                            {content?.[0]?.title}
                        </span>
                    )
                } 
            </div>
        );
    }
}

export default Head;
