import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import styles from './index.css';

class FeedList extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    feedText = () => {
        const { content } = this.props;

        return (
            content.slice(1, 4).map((item, index) => {
                return (
                    <a
                        key={index}
                        href={item.url}
                        target="_blank"
                        className={styles.feedText}
                    >
                        {item.title}
                    </a>
                );
            })
        );
    }

    firstFeed = () => {
        const { content } = this.props;
        const imageUrl = content?.[0].thumbnails?.image?.[0]?.url;

        return (
            <a href={content?.[0].url} target="_blank" className={styles.firstFeed}>
                <img
                    src={formatImageWebpAuto(imageUrl, 96, 54)}
                    className={styles.firstFeedImg}
                />
                <div className={styles.firstFeedText}>
                    {content?.[0].title || ''}
                </div>
            </a>
        );
    }

    render() {
        const { content } = this.props;
        
        return (
            <div className={styles.feedList}>
                {content.map((item, index) => {
                    const imageUrl = item?.thumbnails?.image?.[0]?.url;

                    return (
                        <a
                            key={index}
                            href={item.url}
                            target="_blank"
                            className={ styles.firstFeed }
                        >
                            { index === 0 ?
                                 <>
                                    <img
                                        src={formatImageWebpAuto(imageUrl, 96, 54)}
                                        className={styles.firstFeedImg}
                                    />
                                    <div className={styles.firstFeedText}>
                                        {item.title || ''}
                                    </div>
                                </> : ''
                            }
                            
                            { index !== 0 ?
                                <div className={styles.firstFeedTextLine}>
                                   <span className={styles.dot} />
                                   {item.title || ''}
                                </div> : ''
                            }
                            
                        </a>
                    );
                })}
            </div>
        );
    }
}

export default FeedList;
