import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import Slides from '@ifeng-fe/ui_pc_slides';
import styles from './index.css';

class Slide extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    sliderTmpl = item => {
        return (
            <div className={styles.big_pic}>
                <a href={item.url} className={styles.pic_img} target="_blank" data-nomask>
                    <img src={formatImageWebpAuto(item.thumbnail, 640, 360)} className={styles.trans} />
                </a>
                <div className={styles.text} />
                <p className={styles.title}>
                    <a href={item.url} target="_blank" data-innermask>
                        {item.title}
                    </a>
                </p>
            </div>
        );
    };

    filterData = () => {
        const { content } = this.props;

        if (!content || !Array.isArray(content)) return [];

        return content.map((item) => ({
            ...item,
            thumbnail: item?.thumbnails?.image?.[0]?.url
        }));
    };

    render() {
        const config = {
            dots: true,
            dotsAction: 'hover',
            autoplayInterval: 5000,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.current,
            sliderTmpl: this.sliderTmpl,
        };
        const slideData = this.filterData();
        
        return (
            <div className={styles.slide}>
                <Slides content={slideData} config={config} />
            </div>
        );
    }
}

export default Slide;
