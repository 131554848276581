import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

class ImageList extends PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;
        
        return (
            content && content.length > 0 && <div className={styles.imageList}>
                {content.map((item, index) => {
                    return (
                        <a
                            key={index}
                            href={item.goUrl}
                            className={styles.imageListBox}
                            target="_blank"
                        >
                            <img src={item.imageUrl} className={styles.imageListPic} />
                        </a>
                    );
                })}
            </div>
        );
    }
}

export default ImageList;
